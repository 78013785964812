<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card" id="card_section">
          <div class="card-title p-3 border-bottom">
            <div class="d-flex align-items-center">
              <div class="me-2"><i class="uil uil-dollar-alt display-6 text-primary"></i></div>
              <div>
                <h4 class="fw-medium font-size-20 text-info mb-0"> {{ title }}</h4>
                <small class="d-block font-size-13 text-muted fw-normal">All Financial Records</small>
              </div>
            </div>
          </div>
          
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md col-xl">
                <date-picker
                  v-model="date_range"
                  type="date"
                  range
                  value-type="format" 
                  format="YYYY-MM-DD"
                  placeholder="Select Date Range"
                  :disabled="disable_date"
                  :disabled-date="notBeforeToday"
                  :clearable="false"
                  :editable="false"
                  :shortcuts="shortcuts"
                  @change="DateChanged()"
                  class="mb-2"
                ></date-picker>
              </div>
              <div class="col-12 col-md-auto col-xl">
                <div class="app-search2 position-relative">
                  <input
                    type="search"
                    placeholder="Search..."
                    class="form-control mb-2"
                    v-model.trim="search_value"
                    @search="search()"
                  />
                  <span class="uil-search text-info"></span>
                </div>
              </div>
              <div class="col-12 col-md-auto col-xl-auto">
                <div class="row">
                  <div class="col-6 pe-1 col-auto d-grid">
                    <button
                      class="btn btn-light mb-3 text-nowrap"
                      type="button"
                      @click="clear()"
                    >
                      <i class="uil-redo me-1"></i> Reset
                    </button>
                  </div>
                  <div class="col-6 ps-1 col-auto d-grid">
                    <button
                      class="btn btn-info text-white mb-3 text-nowrap"
                      type="button"
                      @click="search()"
                    >
                      <i class="uil-search me-1"></i> Search
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl">
                <div class="float-end">
                  <div class="mb-3 ml-auto ">
                    <b-button
                      type="submit"
                      variant="info"
                      @click="exportData()"
                      :disabled="disableExport"
                    >
                      <i class="uil-file-export me-1"></i>
                      Export
                      <div
                        class="spinner-border spinner-border-sm text-white"
                        v-if="loadingButton"
                        role="status"
                      ></div>
                    </b-button>
                    
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive text-nowrap font-size-14">
             
              <table class="table mt-2 mb-0 align-middle min-width-760" :class="{'table-hover':returnData.length>0}">
                <thead class="text-uppercase">
                  <tr>
                    <th>Date Range</th>
                    <th>Merchant Code</th>
                    <th class="text-end">Total Income</th>
                    <th class="text-end">Total Company Profit (5%)</th>
                    <th class="text-end">Total Merchant Nett Amount</th>
                    <th class="text-end">Total Withdrawal</th>
                    <th class="text-end">Total Current Balance</th>
                    <th class="text-center">Transaction </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!returnData.length && !loading">
                    <td colspan="8" height="300" class="text-center text-muted">
                      <i class="uil uil-dollar-alt me-1"></i> No Data Available
                    </td>
                  </tr>
                  <tr v-if="loading">
                    <td colspan="8" class="text-center text-muted" height="300">
                      <div
                        class="spinner-border text-secondary my-2"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                      <br />
                      <div>Loading...</div>
                    </td>
                  </tr>
                  <tr v-for="(value, index) in returnData" :key="index">
                    <td>
                      {{ date_range[0]}} ~ {{ date_range[1]}}
                    </td>
                    <td>
                      {{ value.merchant_code }}
                    </td>
                    <td class="text-end">
                      {{ currencySymbol }}  {{  convertCurrencyFormat(value.total_income) }}
                    </td>
                    <td class="text-end text-primary fw-medium">
                      {{ currencySymbol }}  {{  convertCurrencyFormat(value.total_profit) }} 
                    </td>
                    <td class="text-end">
                      {{ currencySymbol }} {{  convertCurrencyFormat(value.total_merchant_nett_amount) }}
                    </td>
                    <td class="text-end text-danger fw-medium">
                   -   {{ currencySymbol }}  {{  convertCurrencyFormat(value.total_withdrawal) }}
                    </td>
                    <td class="text-end text-info fw-medium">
                      {{ currencySymbol }}  {{  convertCurrencyFormat(value.total_current_balance) }}
                    </td>
                    <td class="text-center">
                      <button class="btn btn-outline-info btn-sm" @click="openModal(value)"><i class="uil uil-eye me-1"></i> View Record</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row" v-if="returnData.length">
              <div class="col mt-3">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      hide-ellipsis
                      @change="handlePageChange"
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="totalData"
                    >
                    </b-pagination>
                  </ul>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
    <b-modal
      size="lg"
      @hidden="resetModal"
      ref="modal-records"
      centered
      hide-footer
      hide-header
      content-class="overflow-hidden"
    >
      <div class="row align-items-center">
        <div class="col-12 text-white" >
          <h5 class="text-info mb-3"><i class="uil uil-history me-1 text-primary"></i> {{ modalObject.merchant_code }} - Transaction Records</h5>
        </div>
        <div class="col-12">
          <date-picker
            v-model="modalDateRange"
            type="date"
            range
            value-type="format" 
            format="YYYY-MM-DD"
            placeholder="Select Date Range"
            :disabled="disable_date"
            :disabled-date="notBeforeToday"
            :clearable="false"
            :editable="false"
            :shortcuts="shortcuts"
            @change="modalDateChanged()"
            class="mb-2"
          ></date-picker>
        </div>
        <div class="col-12">
          <div class="table-responsive text-nowrap font-size-14">
             
             <table class="table mt-2 mb-0 align-middle" :class="{'table-striped ':modalData.length>0}">
               <thead class="text-uppercase">
                 <tr>
                   <th>Transaction Date</th>
                   <th class="text-end">Cash In</th>
                   <th class="text-end">Cash Out</th>
                   <th class="text-end">Balance (  {{ currencySymbol }}  )</th>
                 </tr>
               </thead>
               <tbody>
                 <tr v-if="!modalData.length && !modalLoading">
                   <td colspan="4" height="300" class="text-center text-muted">
                     <i class="uil uil-dollar-alt me-1"></i> No Data Available
                   </td>
                 </tr>
                 <tr v-if="modalLoading">
                   <td colspan="4" class="text-center text-muted" height="300">
                     <div
                       class="spinner-border text-secondary my-2"
                       role="status"
                     >
                       <span class="sr-only">Loading...</span>
                     </div>
                     <br />
                     <div>Loading...</div>
                   </td>
                 </tr>
                 <tr v-for="(value, index) in modalData" :key="index">
                   <td>
                    {{value.transaction_date }}
                   </td>
                   <td class="text-end text-primary">
                    
                     <span v-if="value.cash_in !=='0'">
                      +{{ convertCurrencyFormat(value.cash_in) }}
                    </span>
                   </td>
                   <td class="text-end text-danger">
                    <span v-if="value.cash_out !=='0'">
                      -{{ convertCurrencyFormat(value.cash_out) }}
                    </span>
                   
                   </td>
                   <td class="text-end text-info fw-medium">
                    {{ convertCurrencyFormat(value.balance) }} 
                   </td>
                  
                 </tr>
               </tbody>
             </table>
            </div>
        </div>
        <div class="col-12 mt-3" v-if="modalData.length">
          <div
              class="dataTables_paginate paging_simple_numbers float-end">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination
                  hide-ellipsis
                  @change="handleModalPageChange"
                  v-model="modalCurrentPage"
                  :per-page="perPage"
                  :total-rows="modalTotalData"
                >
                </b-pagination>
              </ul>
            </div>
        </div>
        
      </div>
    </b-modal>
    <Lottie :path="'animate/loading-168.json'" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
  </Layout>
</template>
<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "vue2-datepicker";
/**
 * Settlement Page
 */
const PageTitle = "Financial Reports"

export default {
  components: { 
    Layout, 
    PageHeader, 
    Lottie,
    Common,
    DatePicker
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      accessUsername:'',
      showLottie:false,
      title: '',
      items: [
        {
          text: "OneTouch Pay",
          to: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
      formData:{
        apiID: "YOUR_API_ID",
        apiHash:"YOUR_API_HASH",
      },
      submitted:false,
      loading:false,
      buttonLoading:[false,false,false],
      currentPage: 1,
      currencySymbol:"₹",
      perPage: 20,
      totalData: 0,
      returnData: [],
      export_data: [],
      search_value: "",
      disableExport: false,
      loadingButton: false,
      date_range: null,
      disable_date: false,
      shortcuts:[],
      modalCurrentPage: 1,
      modalTotalData: 0,
      modalData:[],
      modalLoading:false,
      modalDateRange: null,
      modalDisableDate: false,
      modalObject: ""
    };
  },
  middleware: "authentication",
  async mounted(){
   
    this.title = PageTitle
    this.items[1].text = PageTitle
    // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    // this.$refs.main.changeVerticalTopBar("bill",true)
    // this.$refs.main.setShowFooterCert(false)
    // this.$refs.main.setPageTitle('title')
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessUsername = this.$refs.commonFunc.getUsername()
    this.date_range = this.$refs.commonFunc.last30Days()
    this.modalDateRange = this.$refs.commonFunc.last30Days()
    this.shortcuts = this.$refs.commonFunc.datePickerShortcut()
    await this.reload()
    await this.handlePageChange(1);
    
  }, 
  created(){
    
  },
  methods:{
      convertCurrencyFormat(value) {
        // Assuming this.transactionLimit is defined in your component's data
        return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00","");
      },
      openModal(value){
        this.modalObject = value
        this.$refs['modal-records'].show()
        this.modalLoading = true
        this.handleModalPageChange(1)
      },
      modalDateChanged() {
        console.log("datechange" + this.modalDateRange);
        this.handleModalPageChange(1);
        this.modalCurrentPage = 1;
        this.modalDisableDate = true;
      },
      handleModalPageChange(current_page) {
        this.modalCurrentPage = current_page;
        this.getRecord(current_page);
        //this.exportData(1)
      },
      resetModal() {
        this.modalObject = {}
        this.modalDateRange = this.$refs.commonFunc.last30Days();
        this.modalLoading = false;
        this.modalData = []
      },
      async getRecord(pages){
        this.$Progress.start();
        this.modalLoading = true
        this.modalDisableDate = true
        this.modalData = [];
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("username",  this.accessUsername);
        bodyFormData.append("page", pages);
        bodyFormData.append("limit", this.perPage);
        bodyFormData.append("dateStart", this.modalDateRange[0]);
        bodyFormData.append("dateEnd", this.modalDateRange[1]);
        axios({
            method: "post",
            url: `https://www.seveninblank.com/projects/api/pagination.php`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            this.modalData = [];
            var resData = response.data;
            console.log(resData)
            this.modalTotalData = resData.total_products
            var data = []
            if(this.modalObject.id=='01'){
                data = [
                { "id":"01",
                  "transaction_date":"2023-08-15 18:30:00",
                  "cash_in": "0",
                  "cash_out": "4000",
                  "balance": "4000",
                },
                 { "id":"01",
                  "transaction_date":"2023-08-15 15:30:00",
                  "cash_in": "1000",
                  "cash_out": "0",
                  "balance": "8000",
                },
                { "id":"01",
                  "transaction_date":"2023-08-15 12:30:00",
                  "cash_in": "500",
                  "cash_out": "3000",
                  "balance": "7000",
                },
                { "id":"02",
                  "transaction_date":"2023-08-14 12:30:00",
                  "cash_in": "4000",
                  "cash_out": "0",
                  "balance": "9500",
                },
                
                { "id":"03",
                  "transaction_date":"2023-08-13 12:30:00",
                  "cash_in": "5500",
                  "cash_out": "0",
                  "balance": "5500",
                },
                { "id":"04",
                  "transaction_date":"2023-08-12 15:30:00",
                  "cash_in": "0",
                  "cash_out": "1350",
                  "balance": "0",
                },
                { "id":"04",
                  "transaction_date":"2023-08-12 14:30:00",
                  "cash_in": "1350",
                  "cash_out": "0",
                  "balance": "1350",
                },
                { "id":"05",
                  "transaction_date":"2023-08-12 12:30:00",
                  "cash_in": "0",
                  "cash_out": "0",
                  "balance": "0",
                },
              ]
            }

            if(this.modalObject.id=='02'){
                data = [
                { "id":"01",
                  "transaction_date":"2023-08-12 15:30:00",
                  "cash_in": "0",
                  "cash_out": "500",
                  "balance": "1400",
                },
                { "id":"02",
                  "transaction_date":"2023-08-12 14:30:00",
                  "cash_in": "1000",
                  "cash_out": "0",
                  "balance": "1900",
                },
                { "id":"03",
                  "transaction_date":"2023-08-12 14:30:00",
                  "cash_in": "900",
                  "cash_out": "0",
                  "balance": "900",
                },
                { "id":"04",
                  "transaction_date":"2023-08-12 12:30:00",
                  "cash_in": "0",
                  "cash_out": "0",
                  "balance": "0",
                },
              ]
            }
           
           
            for (var i in data) {
                const listData = {
                  "id":data[i].id,
                  "transaction_date": data[i].transaction_date,
                  "cash_in": data[i].cash_in,
                  "cash_out": data[i].cash_out,
                  "balance": data[i].balance,
                };
                this.modalData.splice(i, 0, listData);
              }
            this.modalLoading = false;
            this.modalDisableDate = false
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.modalLoading = false;
            this.modalDisableDate = false
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
      notBeforeToday(date) {
        return date > new Date(new Date().setHours(0, 0, 0, 0));
      },
      DateChanged() {
        console.log("datechange" + this.date_range);
        this.handlePageChange(1);
        this.currentPage = 1;
        this.disable_date = true;
      },
      handlePageChange(current_page) {
        this.pageNum = current_page;
        this.currentPage = current_page;
        this.getData(this.pageNum, this.search_value);
        //this.exportData(1)
      },

      search() {
        this.handlePageChange(1);
        this.currentPage = 1;
      },
      clear() {
        this.search_value = "";
        this.handlePageChange(1);
        this.currentPage = 1;
      },

      exportToExcel(Data,name,type) {
        var title = name.replace(/ /g, "_");
        var ws = window.XLSX.utils.json_to_sheet(Data);
        let objectMaxLength = []; 
            for (let i = 0; i < Data.length; i++) {
            let value = Object.values(Data[i]);
            for (let j = 0; j < value.length; j++) {
                if (typeof value[j] == "number") {
                objectMaxLength[j] = 5;
                } else {
                objectMaxLength[j] =
                    objectMaxLength[j] >= value[j].length
                    ? parseInt(objectMaxLength[j])
                    : parseInt(value[j].length) + 5;
                }
            }
        }
        var wscols =[]
        objectMaxLength.forEach((value, index) => {
          console.log(value)
          wscols.push({width: objectMaxLength[index]})
        }),
        ws['!cols'] = wscols;
        
        var wb = window.XLSX.utils.book_new();
        window.XLSX.utils.book_append_sheet(wb, ws, name)
        window.XLSX.writeFile(wb, title +'.' + (type || 'xlsx'))
    },
    async getData(pages, keyword){
        this.$Progress.start();
        this.loading = true
        this.disable_date = true
        this.returnData = [];
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("username",  this.accessUsername);
        bodyFormData.append("page", pages);
        bodyFormData.append("limit", this.perPage);
        bodyFormData.append("keyword", keyword);
        bodyFormData.append("dateStart", this.date_range[0]);
        bodyFormData.append("dateEnd", this.date_range[1]);
        axios({
            method: "post",
            url: `https://www.seveninblank.com/projects/api/pagination.php`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            this.returnData = [];
            var resData = response.data;
            console.log(resData)
            this.totalData = 2
            var data = [
                { "id":"01",
                  "merchant_code": "M00002",
                  "total_income": "13000",
                  "total_profit": "650",
                  "total_merchant_nett_amount": "12350",
                  "total_withdrawal": "8350",
                  "total_current_balance": "4000",
                },
                {
                 "id":"02",
                  "merchant_code": "M00003",
                  "total_income": "2000",
                  "total_profit": "100",
                  "total_merchant_nett_amount": "1900",
                  "total_withdrawal": "500",
                  "total_current_balance": "1400",
                }
            ]
           
            for (var i in data) {
                const listData = {
                  "id":data[i].id,
                  "merchant_code": data[i].merchant_code,
                  "total_income": data[i].total_income,
                  "total_profit": data[i].total_profit,
                  "total_merchant_nett_amount": data[i].total_merchant_nett_amount,
                  "total_withdrawal": data[i].total_withdrawal,
                  "total_current_balance": data[i].total_current_balance,
                };
                this.returnData.splice(i, 0, listData);
              }
             
            // if (resData.status == 200) {
            //   console.log(resData)
            //   this.totalData = 10
            // } 
            // else if (resData.status == 401){
            //   Swal.fire(
            //       {
            //       icon: 'error',
            //       title: 'Oops...',
            //       html: `${resData.message}.`,
            //       confirmButtonColor: '#222',
            //       confirmButtonText: this.$t('siteLang.Done'),
            //     })
            //     this.$refs.commonFunc.clearData()
            //     this.$router.push({
            //         path: "/login",
            //   });
            // }
            // else {
            //     Swal.fire(
            //       {
            //       icon: 'error',
            //       title: 'Oops...',
            //       html: `${resData.message}.`,
            //       confirmButtonColor: '#222',
            //       confirmButtonText: this.$t('siteLang.Done'),
            //     })
            // }
            this.loading = false;
            this.disable_date = false
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.disable_date = false
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    async exportData(){
        this.$Progress.start();
        this.disableExport = true;
        this.loadingButton = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("phone",  this.accessPhone);
        // bodyFormData.append("page", pages);
        // bodyFormData.append("limit", this.perPage);
        axios({
            method: "get",
            url: `https://dummyjson.com/users/search?q=&limit=100&skip=1`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            this.export_data = [];
            var resData = response.data;
            this.totalData = resData.total
            var users = resData.users
           

            for (var i in users) {
                 // Combine date and time
                 const depositDateTime = `29 Jun 2023, 12:30:00`;
                // Generate a random deposit amount
                const randomDeposit = Math.floor(Math.random() * 10000) + 1000; // Random amount between 1000 and 11000

                // Calculate merchant fee and nett amount
                const merchantFeePercentage = 2.5 / 100; // 2.5% merchant fee
                const merchantFee = Math.floor(randomDeposit * merchantFeePercentage);
                const nettAmount = randomDeposit - merchantFee;
                const randomStatus = Math.random() < 0.8 ? "success" : "failed";
                const listData = {
                  "id":"ids",
                  "deposit_date": depositDateTime,
                  "customer_id": `${users[i].id}`,
                  "customer_name":`${users[i].firstName} ${users[i].lastName}`,
                  "deposit_amount": randomDeposit.toString(),
                  "deposit_mode": "UPI",
                  "merchant_fee": merchantFee.toString(),
                  "nett_amount": nettAmount.toString(),
                  "status": randomStatus,
                };
                this.export_data.splice(i, 0, listData);
              }
              var self = this
              setTimeout(function() {  
                self.disableExport = false;
                self.loadingButton = false;
                self.exportToExcel(self.export_data,'Summary','xlsx')
              }, 1200);
             
            // if (resData.status == 200) {
            //   console.log(resData)
            //   this.totalData = 10
            // } 
            // else if (resData.status == 401){
            //   Swal.fire(
            //       {
            //       icon: 'error',
            //       title: 'Oops...',
            //       html: `${resData.message}.`,
            //       confirmButtonColor: '#222',
            //       confirmButtonText: this.$t('siteLang.Done'),
            //     })
            //     this.$refs.commonFunc.clearData()
            //     this.$router.push({
            //         path: "/login",
            //   });
            // }
            // else {
            //     Swal.fire(
            //       {
            //       icon: 'error',
            //       title: 'Oops...',
            //       html: `${resData.message}.`,
            //       confirmButtonColor: '#222',
            //       confirmButtonText: this.$t('siteLang.Done'),
            //     })
            // }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.disableExport = false;
            this.loadingButton = false;
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.formData.apiID = data.apiID;
          this.formData.apiHash = data.apiHash;
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },

  }
};
</script>